@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.not-found {
  background-color: $light;
  padding: 20px 0 40px 0;

  &-wrapper {
    display: grid;
    justify-content: center;
    justify-items: center;
    margin-top: 80px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  &-icon {
    background-size: 100%;
    height: 100px;
    width: 100px;
  }

  &-title {
    max-width: 483px;
    text-align: center;
    justify-self: center;
    align-self: center;

    @include media-breakpoint-down(sm) {
      max-width: 364px;
      font-size: 24px;
    }
  }

  &-body {
    max-width: 541px;
    margin-top: 16px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      max-width: 344px;
      font-size: 14px;
    }
  }

  &-button {
    min-width: 423px;
    margin-top: 67px;

    @include media-breakpoint-down(sm) {
      min-width: 200px;
      margin-top: 40px;
    }
  }
}
